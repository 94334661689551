<template>
  <v-dialog
    v-model="logDialog"
    persistent
    max-width="900"
    max-height="70vh"
    overlay-opacity=".8"
  >
    <v-card v-if="!singleDraftLog" class="pb-1">
      <v-card-title class="py-0 px-1">
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t("card.log-detailed") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="logDialog = false" v-on="on" v-bind="attrs">
                <v-icon>{{ iconClose }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.close") }}</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-skeleton-loader
        class="mx-auto"
        max-width="100%"
        type="article, actions"
      ></v-skeleton-loader>
    </v-card>

    <v-card class="pb-1" v-if="singleDraftLog">
      <v-card-title class="py-0 px-1">
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t("card.log-detailed") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="logDialog = false" v-on="on" v-bind="attrs">
                <v-icon>{{ iconClose }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.close") }}</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            v-html="$t('text.this-is-memo-detailed-log')"
            v-if="singleDraftLog && singleDraftLog.length > 0"
          >
          </v-col>
          <v-col>
            <v-list style="max-height: 60vh" class="overflow-y-auto">
              <v-list-item v-for="(item, idx) in singleDraftLog" :key="idx">
                <v-list-item-avatar>
                  <v-icon color="primary lighten-1">
                    {{ iconCalendar }}
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="list__no-padding">
                  <v-list-item-title v-text="item.action"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="indoDate(item.created_at)"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <p
              v-if="singleDraftLog && singleDraftLog.length < 1"
              class="text-center mt-3"
            >
              {{ $t("text.log-is-empty") }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-2" text @click="logDialog = false">
          <v-icon class="mr-1">{{ iconClose }}</v-icon>
          {{ $t("button.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiCalendarToday, mdiCloseCircleOutline } from "@mdi/js";
import { mapActions, mapState } from "vuex";

export default {
  name: "log-modal",
  props: {
    showLogDialog: { type: Boolean },
    memoId: { type: String },
  },
  data: () => ({
    comment: null,
    iconClose: mdiCloseCircleOutline,
    iconCalendar: mdiCalendarToday,
  }),
  watch: {
    showLogDialog(val) {
      if (!val) return;
      this.getDraftLog({
        memoId: this.memoId,
      });
    },
  },
  computed: {
    ...mapState("draft", ["singleDraftLog"]),
    logDialog: {
      get() {
        return this.showLogDialog;
      },
      set(val) {
        this.$emit("showHideLogDialog", val);
      },
    },
  },
  methods: {
    ...mapActions("draft", ["getDraftLog"]),
  },
};
</script>
<style>
.list__no-padding{
 padding: 0px;
}
</style>