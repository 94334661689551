<template>
  <v-dialog
    v-model="revInfoDialog"
    persistent
    max-width="1024"
    overlay-opacity=".8"
  >
    <v-card class="pb-1">
      <v-card-title class="headline py-0 px-1">
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t("card.revision-details") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="revInfoDialog = false" v-on="on" v-bind="attrs">
                <v-icon>{{ iconClose }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.close") }}</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text v-if="singleRevisionInfo">
        <v-row>
          <v-col cols="12" class="mb-2">
            <span>
              {{ `${$t("text.revision-info-detail-by")} ${reviserName}` }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-card-text>
            <h4 class="pb-3">
                <span style="font-weight:300">{{ $t("text.old-memo-title")}} :</span> {{singleRevisionInfo.hal_lama ? singleRevisionInfo.hal_lama : $t('text.no change yet')}}
              </h4>
            <h4 class="pb-3">
                <span style="font-weight:300">{{ $t("text.new-memo-title")}} :</span> {{singleRevisionInfo.hal_baru ? singleRevisionInfo.hal_baru : $t('text.no change yet')}}
              </h4>
            <h4 class="pb-1">{{ $t("text.memo-message") }} :</h4>
            <froalaView
                v-if="singleRevisionInfo && !axiosLoading"
                v-model="singleRevisionInfo.perubahan"
                class="px-4 py-3"
              ></froalaView>
          </v-card-text>
        </v-row>
        
      </v-card-text>
      <v-card-text v-if="!singleRevisionInfo && !axiosLoading">
        <v-row>
          <v-col cols="12" class="pa-2">
            <p class="text-center mt-4" v-html="$t('text.no-data')"></p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="!singleRevisionInfo && axiosLoading">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="article, actions"
        ></v-skeleton-loader>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-2" text @click="revInfoDialog = false">
          <v-icon class="mr-1">{{ iconCancel }}</v-icon>
          {{ $t("button.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  mdiClose,
  mdiCloseCircleOutline,
  mdiHistory,
  mdiTextBoxCheckOutline,
  
} from "@mdi/js";
import { mapState, mapActions } from "vuex";

export default {
  name: "revision-info",
  props: {
    showRevInfoDialog: { type: Boolean },
    memoRevId: { type: Object },
    reviserName: { type: String },
  },
  data: () => ({
    comment: null,
    iconCancel: mdiCloseCircleOutline,
    iconResult: mdiTextBoxCheckOutline,
    iconBefore: mdiHistory,
    iconClose: mdiClose,
    tab: 1,
  }),
  computed: {
    ...mapState("draft", ["singleRevisionInfo", "axiosLoading"]),
    revInfoDialog: {
      get() {
        return this.showRevInfoDialog;
      },
      set(val) {
        this.$emit("showHideRevInfoDialog", val);
      },
    },
  },
  watch: {
    showRevInfoDialog(val) {
      if (!val) return;
      this.getSingleRevisionInfo(this.memoRevId);
    },
  },
  methods: {
    ...mapActions("draft", ["getSingleRevisionInfo", "setSingleRevisionInfo"]),
  },
};
</script>